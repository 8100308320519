import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import { SlideYUpTransition } from "vue2-transitions";
import DatePicker from "vue2-datepicker";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { VPopover as Popover } from "v-tooltip";
import { dateRangeBoundaries } from "@/utils/dates";
export * from "@/utils/dates";
dayjs.extend(customParseFormat);
window.dayjs = dayjs;
const FORMAT = "YYYY-MM-DD";
const INTERNAL_FORMAT = "DD/MM/YYYY";
export default {
  props: {
    value: {
      type: String,
      default: undefined
    },
    allowEmpty: {
      type: Boolean,
      default: false
    },
    fullWidthButton: {
      type: Boolean,
      default: false
    },
    buttonAlign: {
      type: String,
      default: "center"
    },
    boundaries: {
      type: Array,
      default() {
        return [undefined, undefined];
      }
    },
    absoluteTitle: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // picker date
      date: "",
      // input date
      inputDate: "",
      defaultDate: "",
      showCalendar: false,
      lang: {
        formatLocale: {
          months: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
          monthsShort: ["Янв", "Фев", "Март", "Апр", "Май", "Июнь", "Июль", "Авг", "Сен", "Окт", "Ноя", "Дек"],
          weekdaysMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
          firstDayOfWeek: 1
        }
      },
      scrollableParents: []
    };
  },
  mounted() {
    const scrollable = [];
    let node = this.$el;
    while (node) {
      if (node.classList && node.classList.contains("ps-container") && node.__vue__) {
        scrollable.push(node);
      }
      node = node.parentNode;
    }
    scrollable.forEach(node => {
      node.__vue__.$on("ps-scroll-x", this.forceUpdatePosition);
      node.__vue__.$on("ps-scroll-y", this.forceUpdatePosition);
    });
    this.scrollableParents = scrollable;
  },
  beforeDestroy() {
    this.scrollableParents.forEach(node => {
      node.__vue__.$off("ps-scroll-x", this.forceUpdatePosition);
      node.__vue__.$off("ps-scroll-y", this.forceUpdatePosition);
    });
  },
  methods: {
    isDateDisabledForPicker(dateNative) {
      const date = dayjs(dateNative);
      if (this.boundariesDates[0] && date < this.boundariesDates[0]) {
        return true;
      }
      if (this.boundariesDates[1] && date > this.boundariesDates[1]) {
        return true;
      }
      return false;
    },
    onPickerInput(v) {
      const boundaries = this.boundariesDates.map(i => i ? i.format(INTERNAL_FORMAT) : i);
      let range = dateRangeBoundaries([v, v], boundaries, INTERNAL_FORMAT);
      this.date = range[1];
      this.inputDate = range[1];
    },
    onInput(v, index) {
      this.inputDate[index] = v;
      if (v === "" && this.allowEmpty) {
        this.$set(this.date, index, v);
      }
      let date = dayjs(v, INTERNAL_FORMAT);
      if (!date.isValid()) {
        return;
      }
      if (this.boundariesDates[0] && date < this.boundariesDates[0]) {
        date = this.boundariesDates[0];
      }
      if (this.boundariesDates[1] && date > this.boundariesDates[1]) {
        date = this.boundariesDates[1];
      }
      if (index === 0) {
        const dateEnd = dayjs(this.date[1], INTERNAL_FORMAT);
        if (date > dateEnd) {
          date = dateEnd;
        }
      } else {
        const dateStart = dayjs(this.date[0], INTERNAL_FORMAT);
        if (date < dateStart) {
          date = dateStart;
        }
      }
      this.$set(this.date, index, date.format(INTERNAL_FORMAT));
    },
    onInputBlur() {
      this.inputDate = this.date;
    },
    forceUpdatePosition() {
      var _this$$refs$popover;
      const popper = (_this$$refs$popover = this.$refs.popover) === null || _this$$refs$popover === void 0 ? void 0 : _this$$refs$popover.popperInstance;
      if (popper) {
        popper.scheduleUpdate();
      }
    },
    openCalendar() {
      this.showCalendar = true;
    },
    closeCalendar() {
      this.showCalendar = false;
    },
    toogleCalendar() {
      this.showCalendar = !this.showCalendar;
    },
    change() {
      this.valueComputed = this.date;
      this.closeCalendar();
    },
    resetChange() {
      this.onPickerInput(this.boundariesDates.map(i => i.format(INTERNAL_FORMAT)));
    }
    // setDateRange(days) {
    //     const lastAvailableDate = (this.boundariesDates?.[1] || dayjs()).format(INTERNAL_FORMAT)
    //     this.onPickerInput(dateRangeFromDate(lastAvailableDate, days, INTERNAL_FORMAT))
    // }
  },
  created() {
    if (this.allowEmpty) {
      this.defaultDate = "";
    } else {
      this.defaultDate = this.valueComputed;
    }
  },
  watch: {
    valueComputed: {
      immediate: true,
      handler(v) {
        this.date = v;
        this.inputDate = v;
      }
    }
  },
  computed: {
    boundariesDates() {
      return this.boundaries.map(i => i ? dayjs(i, FORMAT) : i);
    },
    valueComputed: {
      get() {
        if (!this.value) {
          return "";
        }
        if (!this.value) {
          return this.value;
        }
        return dayjs(this.value, FORMAT).format(INTERNAL_FORMAT);
      },
      set(v) {
        if (!v) {
          if (this.allowEmpty) {
            return v;
          } else {
            return dayjs(this.defaultDate, INTERNAL_FORMAT).format(FORMAT);
          }
        }
        const outputValue = dayjs(v, INTERNAL_FORMAT).format(FORMAT);
        if (!outputValue) {
          this.$emit("input", undefined);
          return;
        }
        this.$emit("input", outputValue);
      }
    },
    calendarDateFrom() {
      var _this$valueComputed;
      return (_this$valueComputed = this.valueComputed) === null || _this$valueComputed === void 0 ? void 0 : _this$valueComputed.replace("/", ".").replace("/20", ".");
    }
  },
  components: {
    DatePicker,
    SlideYUpTransition,
    Popover
  }
};