var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "range-calendar",
    attrs: {
      "disabled": _vm.disabled
    }
  }, [_vm.absoluteTitle ? _c('div', {
    staticClass: "absolute-title"
  }, [_vm._v(" " + _vm._s(_vm.absoluteTitle) + " ")]) : _vm._e(), _c('div', {
    ref: "popperAnchor",
    staticClass: "range-calendar__btn",
    class: {
      'range-calendar__btn--full-width': _vm.fullWidthButton
    }
  }, [_c('popover', {
    ref: "popover",
    attrs: {
      "disabled": _vm.disabled,
      "open": _vm.showCalendar,
      "auto-hide": "",
      "trigger": "manual",
      "placement": "bottom-end"
    },
    on: {
      "hide": _vm.closeCalendar
    }
  }, [_c('button', {
    staticClass: "btn-outline btn-calendar",
    class: [{
      'btn-active': _vm.showCalendar
    }, `align-${_vm.buttonAlign}`],
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.toogleCalendar.apply(null, arguments);
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z",
      "fill": "black",
      "fill-opacity": "0.9"
    }
  })]), !_vm.calendarDateFrom ? [_vm._v(" Не выбрано ")] : [_vm._v(" " + _vm._s(_vm.calendarDateFrom) + " ")]], 2), _c('template', {
    slot: "popover"
  }, [_c('div', [_c('slide-y-up-transition', {
    attrs: {
      "appear": ""
    }
  }, [_c('div', {
    staticClass: "range-calendar__popup",
    class: _vm.$store.getters.themeClass
  }, [_c('date-picker', {
    attrs: {
      "value": _vm.date,
      "editable": false,
      "lang": _vm.lang,
      "inline": "",
      "range-separator": " - ",
      "format": "DD/MM/YYYY",
      "value-type": "DD/MM/YYYY",
      "disabled-date": _vm.isDateDisabledForPicker
    },
    on: {
      "input": function ($event) {
        return _vm.onPickerInput($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "icon-clear",
      fn: function () {
        return [_c('div')];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_c('button', {
          staticClass: "btn btn-outline",
          on: {
            "click": _vm.resetChange
          }
        }, [_vm._v(" Очистить ")]), _c('button', {
          staticClass: "btn-blue",
          on: {
            "click": _vm.change
          }
        }, [_vm._v(" Применить ")])];
      },
      proxy: true
    }])
  })], 1)])], 1)])], 2)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };